import styled, { css } from "styled-components"

const handleColor = color => {
  switch (color) {
    case "main":
      return "#8f5597"
    case "white":
      return "#fff"
    default:
      return "#000"
  }
}

const handleWeight = weight => {
  switch (weight) {
    case "bold":
      return "700"
    default:
      return "400"
  }
}

const Heading = css`
  position: relative;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.25;
  color: ${({ color }) => handleColor(color)};
  & a,
  & button {
    position: relative;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    cursor: pointer;
    background-color: transparent;
    color: #000;
    &:before {
      content: "";
      width: 104%;
      height: 12px;
      background: #02a9e7;
      position: absolute;
      left: 0;
      bottom: 4px;
      opacity: 0.3;
      pointer-events: none;
    }
  }
  & strong {
    font-weight: 700;
  }
`

export const HeadingOne = styled.h1`
  font-family: "futura-medium", sans-serif;
  font-style: italic;
  font-size: 100px;
  line-height: 1.2;
  @media screen and (max-width: 1280px) {
    font-size: 60px;
  }
  @media screen and (max-width: 500px) {
    font-size: 22px;
  }
`

export const HeadingTwo = styled.h2`
  ${Heading}
  font-size: 70px;

  @media screen and (max-width: 1280px) {
    font-size: 50px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 70px;
  }

  @media screen and (max-width: 500px) {
    font-size: 40px;
  }
`
export const HeadingThree = styled.h3`
  ${Heading}
  font-size: 30px;

  @media screen and (max-width: 500px) {
    font-size: 24px;
  }
`

export const TextSmall = styled.p`
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: ${({ weight }) => handleWeight(weight)};
  line-height: 1.5;
  & strong {
    font-weight: 700;
  }
`

export const Text = styled.p`
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 28px;
  font-weight: ${({ weight }) => handleWeight(weight)};
  color: ${({ color }) => handleColor(color)};
  & a {
    position: relative;
    color: #000;
    &:before {
      content: "";
      width: 104%;
      height: 8px;
      background: #02a9e7;
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0.3;
      pointer-events: none;
    }
  }
  & strong {
    font-weight: 700;
  }
`

export const TextBig = styled.p`
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: ${({ weight }) => handleWeight(weight)};
  line-height: 1.5;
  color: ${({ color }) => handleColor(color)};
  & strong {
    font-weight: 700;
  }

  @media screen and (max-width: 1280px) {
    font-size: 16px;
  }
`
