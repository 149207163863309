import React from "react"
import styled from "styled-components"
import Sticky from "react-stickynode"

import widgetWhatsapp from "assets/images/widget-whatsapp.png"

const Wrapper = styled.section`
  position: absolute;
  margin: 200px 0 0 0;

  @media screen and (max-width: 500px) {
    display: none !important;
  }
`
const WrapperInner = styled.a`
  display: inline-block;
`

const Image = styled.img`
  width: 50px;
  height: 85px;
`

const WidgetWhatsapp = () => (
  <Sticky innerZ={999} innerActiveClass={"sticky-active"}>
    <Wrapper>
      <WrapperInner href="https://wa.me/+493044013900">
        <Image src={widgetWhatsapp} />
      </WrapperInner>
    </Wrapper>
  </Sticky>
)

export default WidgetWhatsapp
