import React from "react"
import { Helmet } from "react-helmet"

function Seo({ seo, image }) {
  const ogImage = seo.opengraphImage || image

  const metaOgImage = ogImage
    ? {
        property: `og:image`,
        content: ogImage.sourceUrl || image,
      }
    : {}

  return (
    <Helmet
      title={seo.title}
      meta={[
        {
          name: `robots`,
          content: `index, follow`,
        },
        {
          name: `description`,
          content: seo.metaDesc || seo.opengraphDescription,
        },
        {
          property: `og:title`,
          content: seo.opengraphTitle,
        },
        {
          property: `og:description`,
          content: seo.opengraphDescription || seo.metaDesc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: `Kolarski`,
        },
        {
          name: `twitter:title`,
          content: seo.opengraphTitle,
        },
        {
          name: `twitter:description`,
          content: seo.metaDesc || seo.opengraphDescription,
        },
        metaOgImage,
      ]}
    />
  )
}

export default Seo
