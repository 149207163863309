import React from "react"
import styled, { css } from "styled-components"
import reactStringReplace from "react-string-replace"

const Wrapper = styled.div`
  display: flex;
`

const CheckmarkWrapper = styled.div`
  width: 31px;
`
const CheckmarkButton = styled.label`
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -3px;
  margin: 3px 15px 0 0;
  padding: 0;
  background: #fff;
  border: ${({ error }) => (error ? "1px solid #ed454d" : "none !important")};
  width: 16px !important;
  height: 16px !important;
  outline: none;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: #000;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
`

const CheckmarkInput = styled.input`
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  z-index: -999;

  &:checked ~ label:before {
    opacity: 1;
  }
`

const ContentWrapper = styled.div`
  width: calc(100% - 31px);
`

const Content = styled.p`
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 1.35;
  text-align: left;
  color: ${props => (props.contentBlack ? "#000" : "#fff")}; ;
`

const consentAnchorStyles = css`
  cursor: pointer;
  font-weight: 700;
`

const Checkbox = ({
  name,
  content,
  contentBlack,
  value,
  error,
  touched,
  handleChange,
  handleBlur,
  lang,
  setPageVisible,
}) => {
  return (
    <Wrapper>
      <CheckmarkWrapper>
        <CheckmarkInput
          type="checkbox"
          id={`checkbox-${name}`}
          active={value}
          checked={value}
          error={error}
          onChange={handleChange}
          onBlur={handleBlur}
          name={name}
          touched={touched}
        />
        <CheckmarkButton for={`checkbox-${name}`} error={error} />
      </CheckmarkWrapper>
      {content && (
        <ContentWrapper>
          <Content contentBlack={contentBlack}>
            {lang == "en"
              ? reactStringReplace(content, "privacy policy", (match, i) => (
                  <span
                    css={consentAnchorStyles}
                    key={i}
                    onClick={() => setPageVisible(2)}
                  >
                    {match}
                  </span>
                ))
              : reactStringReplace(
                  content,
                  "Datenschutzerklärung",
                  (match, i) => (
                    <span
                      css={consentAnchorStyles}
                      key={i}
                      onClick={() => setPageVisible(2)}
                    >
                      {match}
                    </span>
                  )
                )}
          </Content>
        </ContentWrapper>
      )}
    </Wrapper>
  )
}

export default Checkbox
