import React, { useState } from "react"
import styled from "styled-components"

import LazyImage from "components/shared/LazyImage"
import { Text, HeadingTwo } from "components/shared/Typography"

import arrow from "assets/images/arrow.svg"

const Wrapper = styled.section`
  position: relative;
  overflow: hidden;
  display: flex;
  height: calc(100vh - 50px);

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }
`

const ContentWrapper = styled.div`
  width: 50%;
  background-color: #f1f1f1;
  box-sizing: border-box;
  padding: 7%;

  & ${HeadingTwo} {
    margin: 60px 0;
  }

  & ${Text} {
    margin: 20px 0;
  }

  @media screen and (max-width: 1280px) {
    & ${HeadingTwo} {
      margin: 20px 0;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: calc(100vh - 50px);
  }
`

const Description = styled.ul`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
`

const DescriptionItem = styled.li`
  position: relative;
  height: 50%;
`
const DescriptionLabel = styled.button`
  position: absolute;
  height: 100%;
  width: 50px;
  top: 0;
  right: 0;
  padding: 0 0 20px 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
  background-color: #000;
  color: #000;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  z-index: 3;
  &:before {
    content: "";
    position: absolute;
    right: 12px;
    top: 12px;
    width: 24px;
    height: 24px;
    background: url(${arrow}) no-repeat center / cover;
    transform: ${props => (props.active ? "rotate(0)" : "rotate(-180deg)")};
    transition: transform 300ms ease-in-out;
  }
`

const DescriptionContent = styled.p`
  position: absolute;
  right: ${props => (props.active ? "0" : "-100%")};
  width: 100%;
  height: 100%;
  background-color: #000;
  border-bottom: 1px solid #fff;
  padding: 38px 90px 38px 40px;
  z-index: 2;
  transition: right 300ms ease-in-out;

  @media screen and (max-width: 500px) {
    padding: 20px 80px 20px 20px;
    & ${Text} {
      font-size: 12px;
      line-height: 1.45;
    }
  }
`

const DescriptionLabelInner = styled.span`
  position: absolute;
  left: 18px;
  transform: rotate(-90deg);
  padding: 0;
  font-size: 16px;
  line-height: 1;
  color: #fff;
  height: 50px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: nowrap;
`

const About = ({ sectionId, imageLazy, heading, content, people }) => {
  const [visibleTabs, setVisibleTabs] = useState([])

  const toggleTab = index => {
    visibleTabs.includes(index)
      ? setVisibleTabs(visibleTabs.filter(value => value !== index))
      : setVisibleTabs([...visibleTabs, index])
  }

  return (
    <Wrapper id="company">
      <ContentWrapper>
        <HeadingTwo>{heading}</HeadingTwo>
        {content.map(item => (
          <Text>{item.aboutContentText}</Text>
        ))}
      </ContentWrapper>
      <ImageWrapper>
        <LazyImage src={imageLazy} cover />
        <Description>
          {people.map((item, index) => (
            <DescriptionItem>
              <DescriptionLabel
                active={visibleTabs.includes(index) ? true : false}
                onClick={() => toggleTab(index)}
              >
                <DescriptionLabelInner>{item.name}</DescriptionLabelInner>
              </DescriptionLabel>
              <DescriptionContent
                active={visibleTabs.includes(index) ? true : false}
              >
                <Text color="white">{item.desc}</Text>
              </DescriptionContent>
            </DescriptionItem>
          ))}
        </Description>
      </ImageWrapper>
    </Wrapper>
  )
}

export default About
