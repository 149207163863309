import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`

const Field = styled.input`
  display: flex;
  align-items: center;
  background: #fff;
  border-bottom: none;
  color: ${({ error }) => (error ? "#ed454d" : "#000")};
  border: ${({ error }) => (error ? "1px solid #ed454d" : "none !important")};
  text-align: ${props => (props.textLeft ? "left" : "center")};
  font-size: 16px;
  height: 70px;
  width: 100%;
  padding: 5px 20px;
  width: 100%;
  transition: all 0.3s, cubic-bezier(0.7, 0, 0.4, 1) ease-out;
`

const Label = styled.label`
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  display: none;
`

const Input = ({
  label,
  value,
  error,
  name,
  touched,
  handleChange,
  handleBlur,
  textLeft,
}) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Field
        active={value}
        value={value}
        error={error}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        touched={touched}
        textLeft={textLeft}
        placeholder={label}
      />
    </Wrapper>
  )
}

export default Input
