import React from "react"
import styled, { keyframes } from "styled-components"
import { fadeInDown, fadeIn } from "react-animations"

import { HeadingOne, TextBig } from "components/shared/Typography"

const Wrapper = styled.section`
  display: flex;
  height: calc(100vh - 80px);
  margin: 0 0 80px 0;
  background-color: #eee;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }
`

const fadeAnimation = keyframes`${fadeInDown}`
const opacityAnimation = keyframes`${fadeIn}`

const Header = styled.div`
  position: absolute;
  right: 0;
  top: 125px;
  width: 60vw;

  animation: 1.2s ${fadeAnimation};

  @media screen and (max-width: 1024px) {
    position: relative;
    top: unset;
  }
`

const Content = styled.div`
  position: relative;

  & ${TextBig} {
    margin: 0 0 20px 0;
    animation: 2s ${opacityAnimation};
    &:last-of-type {
      margin: 0;
    }
  }
`

const TextCustom = styled.p`
  position: relative;
  margin: 12px 0 0 0;
  padding: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.25;
  color: #fff;
  animation: 1s ${fadeAnimation};
  & strong {
    font-weight: 700;
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const VideoWrapper = styled.div`
  position: relative;
  width: 67%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

const Box = styled.div`
  position: relative;
  top: 0;
  right: 0;
  width: 33vw;
  background: #fff;
  z-index: 9;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 30px 30px 55px 30px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
    padding: 90px 10% 40px 10%;
  }
`

const Label = styled.div`
  position: absolute;
  top: 60vh;
  right: 0;
  width: calc(40vw + 40px);
  background: #02a9e7;
  padding: 30px 20px;
  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    bottom: 0;
    border-left: 49px solid transparent;
    bottom: -30px;
    left: 0;
    border-right: 0px solid transparent;
    border-top: 30px solid #0089bb;
  }

  & ${TextBig} {
    margin: 0 0 5px 0;
    animation: 1s ${fadeAnimation};
  }

  @media screen and (max-width: 1460px) {
    top: 65vh;
  }

  @media screen and (max-width: 1024px) {
    width: calc(70% + 40px);
    bottom: -40px;
    z-index: 10;
  }

  @media screen and (max-width: 500px) {
    position: relative;
    bottom: unset;
    width: 100%;
    top: -5px;
    &:before {
      display: none;
    }
  }
`

const BannerMain = ({ video, heading, content, labelHeading, labelText }) => {
  return (
    <Wrapper>
      <VideoWrapper>
        <Video src={video} autoPlay muted />
        <Label>
          <TextBig color="white" weight="bold">
            {labelHeading}
          </TextBig>
          <TextCustom color="white">{labelText}</TextCustom>
        </Label>
      </VideoWrapper>
      <Box>
        <Header>
          <HeadingOne>{heading}</HeadingOne>
        </Header>
        <Content>
          {content.map(item => (
            <TextBig>{item.bannerContentText}</TextBig>
          ))}
        </Content>
      </Box>
    </Wrapper>
  )
}

export default BannerMain
