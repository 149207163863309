import styled, { css } from "styled-components"

export const Form = styled.form`
  display: block;
  width: 100%;
`

export const FormRow = styled.div`
  display: flex;
  width: 100%;
  margin: ${props => (props.margin ? props.margin : "0 -10px")};
`

export const FormBlock = styled.div`
  display: flex;
  flex: 1;
  margin: 10px;
`
