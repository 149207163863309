import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import logo from "assets/images/logo-white.png"

const Wrapper = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 999;
`
const BrandWrapper = styled(Link)`
  display: inline-block;
`

const Brand = styled.img`
  width: 200px;
  @media screen and (max-width: 500px) {
    width: 140px;
  }
`

const LangSwitcher = styled.nav`
  position: absolute;
  right: 50px;
  top: 50px;
`
const LangButton = styled(Link)`
  font-size: 16px;
  line-height: 1;
  color: #000;
`

const HeaderMain = ({ lang }) => (
  <Wrapper>
    <BrandWrapper to="/">
      <Brand src={logo} />
    </BrandWrapper>
    <LangSwitcher>
      {lang == "en" ? (
        <LangButton to="/de/">DE</LangButton>
      ) : (
        <LangButton to="/en/">EN</LangButton>
      )}
    </LangSwitcher>
  </Wrapper>
)

export default HeaderMain
