import React from "react"
import styled from "styled-components"

import { ButtonAdd } from "components/shared/Buttons.js"
import { HeadingThree, TextBig } from "components/shared/Typography"

import imageQrCode from "assets/icons/whatsapp-qr.svg"
import patternBlue from "assets/images/pattern-blue.png"

const ContactWhatsappWrapper = styled.section`
  width: 100%;
  background: #02a9e7 url(${patternBlue});
  background-repeat: repeat;
  background-size: auto 100%;
  text-align: center;
  padding: 100px 25vw;
  margin-top: 100px;

  @media screen and (max-width: 1280px) {
    padding: 100px 15vw;
  }

  @media screen and (max-width: 1240px) {
    padding: 100px 10%;
  }

  @media screen and (max-width: 500px) {
    padding: 50px 10%;
  }
`

const ContactWhatsappInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ContactWhatsappTitle = styled(HeadingThree)`
  font-size: 42px;
  text-align: center;

  @media screen and (max-width: 500px) {
    font-size: 32px;
  }
`

const ContactWhatsappImage = styled.img`
  margin: 30px 0;
  width: 200px;
  height: 200px;
`

const ContactWhatsappText = styled(TextBig)`
  text-align: center;
  margin: 0 0 30px 0;

  font-size: 20px;

  @media screen and (max-width: 1280px) {
    font-size: 18px;
  }

  @media screen and (max-width: 720px) {
    font-size: 16px;
  }
`

const ContactWhatsappBtn = styled(ButtonAdd)`
  max-width: 620px;
`

const ContactWhatsapp = ({ title, text, btnText }) => {
  return (
    <ContactWhatsappWrapper>
      <ContactWhatsappInner>
        <ContactWhatsappTitle color="white">{title}</ContactWhatsappTitle>
        <ContactWhatsappImage src={imageQrCode} alt="WhatsApp QR Code" />
        <ContactWhatsappText
          color="white"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <ContactWhatsappBtn
          as="a"
          href="https://whatsapp.com/channel/0029VaCOQ0NHgZWaxjMLoh2b"
          target="_blank"
        >
          {btnText}
        </ContactWhatsappBtn>
      </ContactWhatsappInner>
    </ContactWhatsappWrapper>
  )
}

export default ContactWhatsapp
