const scrollToSection = selector => {
  if (selector.includes(".") || selector.includes("#")) {
    const element = document.querySelector(`${selector}`)
    const scrollY =
      element.getBoundingClientRect().top + window.pageYOffset - 50

    window.scrollTo({ top: scrollY, behavior: "smooth" })
  } else {
    throw new Error("Invalid selector")
  }
}

export default scrollToSection
