import React, { useEffect } from "react"
import styled, { css } from "styled-components"
import Sticky from "react-stickynode"

import scrollToSection from "utils/scrollToSection"

import arrow from "assets/images/arrow.svg"
import iconMiniWhatsapp from "assets/images/widget-whatsapp-mini.png"

const Wrapper = styled.header`
  position: relative;
  width: 100%;
  height: 50px;
  .sticky-outer-wrapper,
  .sticky-inner-wrapper {
    width: 100%;
    height: 50px;
  }
`

const InnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 998;
`

const Navigation = styled.nav`
  display: flex;
  padding: 0;
  width: 100%;
`

const NavigationItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  padding: 0;
  margin: 0;
  height: 80px;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  background-color: ${props => (props.active ? "#02a9e7" : "#fff")};
  color: ${props => (props.active ? "#fff" : "#000")};
  cursor: pointer;
  transition: all 0.3s, cubic-bezier(0.7, 0, 0.4, 1) ease-out;
  &:hover {
    background-color: #02a9e7;
    color: #fff;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`

const NavigationScroll = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  flex: 2;
  padding: 0;
  margin: 0;
  height: 80px;
  cursor: pointer;
  background-color: #000;
  transition: all 0.3s, cubic-bezier(0.7, 0, 0.4, 1) ease-out;
  &:before {
    content: "";
    position: absolute;
    left: 25px;
    width: 35px;
    height: 35px;
    background: url(${arrow}) no-repeat center / cover;
    transform: rotate(90deg);
    transition: 0.3s;
  }
  &:hover {
    background-color: #02a9e7;
    color: #fff;
  }

  @media screen and (max-width: 1024px) {
    flex: 0 50px;
    &:before {
      left: 15px;
    }
  }

  @media screen and (max-width: 500px) {
    display: none !important;
  }
`

const NavigationWhatsapp = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 50px;
  padding: 0;
  margin: 0;
  height: 50px;
  cursor: pointer;
  background-color: #000;
  background-image: url(${iconMiniWhatsapp});
  background-size: 100% auto;
  background-position: left 2px center;
  transition: all 0.3s, cubic-bezier(0.7, 0, 0.4, 1) ease-out;
  @media screen and (min-width: 501px) {
    display: none;
  }
`

const stickyCss = css`
  .sticky-active {
    & ${NavigationItem}, & ${NavigationScroll} {
      height: 50px;
    }
    & ${NavigationScroll} {
      &:before {
        width: 22px;
        height: 22px;
      }
    }
  }
`

const getSectionID = index => {
  switch (index) {
    case 0:
      return "#projects"
    case 1:
      return "#company"
    case 2:
      return "#references"
    case 3:
      return "#contact"
    case 4:
      return "#contact"
    default:
      return "#projects"
  }
}

const HeaderMain = ({ lang, navigationActive, setNavActive }) => {
  const handleScrollClick = () => {
    const projects = document.querySelector("#projects")
    if (projects) {
      const isBeforeFirstSection = window.scrollY + 100 < projects.offsetTop
      if (isBeforeFirstSection) {
        scrollToSection("#projects")
        return
      }
    }

    const sectionID = getSectionID(navigationActive + 1)
    if (sectionID) scrollToSection(sectionID)
  }

  useEffect(() => {
    const projects = document.querySelector("#projects")
    const company = document.querySelector("#company")
    const references = document.querySelector("#references")
    const contact = document.querySelector("#contact")

    const handleScroll = () => {
      const offsets = [projects, company, references, contact]
        .filter(el => el)
        .map(el => {
          const elementCenter = (el.offsetTop + el.offsetHeight) / 2
          const screenCenter = (window.scrollY + window.innerHeight) / 2
          return Math.abs(elementCenter - screenCenter)
        })
      const minOffset = Math.min.apply(Math, offsets)
      const index = offsets.findIndex(el => el === minOffset)
      setNavActive(index)
    }

    window.addEventListener("scroll", handleScroll)
  }, [])

  return (
    <Wrapper>
      <Sticky
        innerZ={999}
        innerActiveClass={"sticky-active"}
        css={stickyCss}
        id="stickyNavigation"
      >
        <InnerWrapper>
          <Navigation>
            <NavigationItem
              active={navigationActive == 0 ? true : false}
              onClick={() => scrollToSection(`#projects`)}
            >
              {lang == "en" ? "Projects" : "Projekte"}
            </NavigationItem>
            <NavigationItem
              active={navigationActive == 1 ? true : false}
              onClick={() => scrollToSection(`#company`)}
            >
              {lang == "en" ? "Company" : "Unternehmen"}
            </NavigationItem>
            <NavigationItem
              active={navigationActive == 2 ? true : false}
              onClick={() => scrollToSection(`#references`)}
            >
              {lang == "en" ? "References" : "Referenzen"}
            </NavigationItem>
            <NavigationItem
              active={navigationActive == 3 ? true : false}
              onClick={() => scrollToSection("#contact")}
            >
              {lang == "en" ? "Contact" : "Kontakt"}
            </NavigationItem>

            <NavigationScroll onClick={handleScrollClick} />
            <NavigationWhatsapp href="https://wa.me/+493044013900" />
          </Navigation>
        </InnerWrapper>
      </Sticky>
    </Wrapper>
  )
}

export default HeaderMain
