import React, { useState } from "react"
import styled, { css } from "styled-components"

import LazyImage from "components/shared/LazyImage"
import { TextBig } from "components/shared/Typography"

import arrow from "assets/images/arrow.svg"

const Wrapper = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
`

const Label = styled.p`
  position: absolute;
  top: 10%;
  left: 10%;
  background: #fff;
  padding: 0 0 0 50px;
  width: 60%;
  border-left: 10px solid #02a9e7;
  box-sizing: border-box;
  height: 100px;
  display: flex;
  align-items: center;
  line-height: 30px;
  font-size: 18px;

  @media screen and (max-width: 500px) {
    padding: 0 0 0 20px;
    left: 5%;
    width: 70%;
    height: 80px;
  }
`

const ImageWrapper = styled.div``

const Buttons = styled.div`
  display: flex;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const Button = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 56px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 4px;
  transition: opacity 0.3s, cubic-bezier(0.7, 0, 0.4, 1) ease-out;
  z-index: 90;
  &:before {
    content: "";
    position: absolute;
    right: 20px;
    width: 24px;
    height: 24px;
    background: url(${arrow}) no-repeat center / cover;
  }
  &:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: 500px) {
    flex: unset;
  }
`

const ButtonDescription = styled.button`
  ${Button};

  background-color: #fff;
  color: #000;
`

const ButtonPage = styled.a`
  ${Button};

  background-color: #000;
  color: #fff;
`

const Description = styled.div`
  position: absolute;
  top: ${({ display }) => (display ? "30%" : "35%")};
  left: 10%;
  background: #fff;
  padding: 40px;
  width: 50%;
  opacity: ${({ display }) => (display ? "1" : "0")};
  transition: all 0.3s, cubic-bezier(0.7, 0, 0.4, 1) ease-out;

  @media screen and (max-width: 500px) {
    left: 5%;
    width: 90%;
    padding: 20px;
  }
`

const DescriptionText = css`
  font-size: 18px;
  line-height: 1.25;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`

const DescriptionClose = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 25px;
  top: 25px;
  width: 18px;
  height: 18px;
  transform: rotate(-45deg);
  background-color: transparent;
  cursor: pointer;
  &:before,
  &:after {
    position: absolute;
    content: "";
    display: block;
    background-color: #000;
  }
  &:before {
    height: 18px;
    width: 2px;
  }
  &:after {
    width: 18px;
    height: 2px;
  }

  @media screen and (max-width: 500px) {
    right: 5px;
    top: 5px;
  }
`

const DescriptionGroup = styled.dl`
  @media screen and (max-width: 500px) {
    display: flex;
    flex-wrap: wrap;
  }
`
const DescriptionLabel = styled.dt`
  ${DescriptionText};
  font-weight: 700;

  margin: 25px 0 0 0;

  &:first-of-type {
    margin: 0;
  }

  @media screen and (max-width: 500px) {
    width: 68%;
    margin: 10px 0 0 0;
  }
`

const DescriptionValue = styled.dd`
  ${DescriptionText};

  margin: 5px 0 0 0;

  @media screen and (max-width: 500px) {
    width: 32%;
  }
`

const ProjectCard = ({
  address,
  imageLazy,
  flats,
  bussinesUnits,
  type,
  year,
  lang,
  website,
}) => {
  const [displayDescription, setDisplayDescription] = useState(false)

  return (
    <Wrapper>
      <ImageWrapper>
        <LazyImage src={imageLazy} cover />
        <Label>
          <TextBig dangerouslySetInnerHTML={{ __html: address }} />
        </Label>
        <Description display={displayDescription}>
          <DescriptionClose onClick={() => setDisplayDescription()} />
          <DescriptionGroup>
            <DescriptionLabel>{lang == "en" ? "Type" : "Typ"}</DescriptionLabel>
            <DescriptionValue>{type}</DescriptionValue>
            <DescriptionLabel>
              {lang == "en" ? "Residentials" : "Wohneinheiten"}
            </DescriptionLabel>
            <DescriptionValue>{flats}</DescriptionValue>
            <DescriptionLabel>
              {lang == "en" ? "Commertials" : "Geschäftseinheiten"}
            </DescriptionLabel>
            <DescriptionValue>{bussinesUnits || "0"}</DescriptionValue>
            <DescriptionLabel>
              {lang == "en" ? "Year" : "Jahr"}
            </DescriptionLabel>
            <DescriptionValue>{year}</DescriptionValue>
          </DescriptionGroup>
        </Description>
      </ImageWrapper>
      <Buttons>
        <ButtonDescription
          onClick={() => setDisplayDescription(!displayDescription)}
        >
          {lang == "en" ? "Description" : "Beschreibung"}
        </ButtonDescription>
        <ButtonPage href={website} target="_blank">
          {lang == "en" ? "Project Page" : "Projektseite"}
        </ButtonPage>
      </Buttons>
    </Wrapper>
  )
}

export default ProjectCard
