import React, { useState } from "react"
import styled from "styled-components"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"

import { HeadingTwo, HeadingThree, Text } from "components/shared/Typography"
import { FormRow, FormBlock } from "components/shared/forms/Form"
import Input from "components/shared/forms/Input"
import Textarea from "components/shared/forms/Textarea"
import Checkbox from "components/shared/forms/Checkbox"
import {
  ButtonMain,
  ButtonSub,
  ButtonActive,
} from "components/shared/Buttons.js"

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

const ContentWrapper = styled.div`
  width: 40%;
  padding: 40px 0 40px 6vw;
  margin-top: 5vw;

  & ${HeadingTwo} {
    margin: 0 0 20px 0;
    max-width: 1024px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;

    & ${HeadingTwo} {
      font-size: 28px;
    }
  }
`

const FormWrapper = styled.div`
  width: 55%;
  margin-top: 5vw;
  position: relative;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

const FormBox = styled.div`
  position: relative;
  padding: 70px 6vw 45px 40px;
  background: #f1f1f1;
  min-height: 350px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: ${props => props.step};
    height: 100%;
    opacity: 0.8;
    background-color: #dbdbdb;
    transition: width 300ms ease-in-out;
  }
`

const FormControls = styled.nav`
  display: flex;
  width: 600px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

const FormStep = styled.div`
  position: relative;
  display: ${props => (props.visible ? "block" : "none")};
  z-index: 10;
`
const FormHeader = styled.div`
  margin: 0 0 15px 0;
`

const Checkboxes = styled.div`
  margin: 55px 0 0 0;
`

const FormThx = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 30px;
  display: ${props => (props.active ? "flex" : "none !important")};
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #02a9e7;
  z-index: 10;

  @media screen and (min-width: 700px) {
    padding: 70px;
  }
`

const contactSchema = Yup.object().shape({
  name: Yup.string().required(),
  surname: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string()
    .min(9)
    .matches(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i)
    .required(),
  message: Yup.string().required(),
  checkbox: Yup.bool().required().oneOf([true]),
})

const Contact = ({ heading, content, setNavActive, lang, setPageVisible }) => {
  const [submitClicked, setSubmitClicked] = useState(false)
  const [continueClicked, setContinueClicked] = useState(false)
  const [formThanks, setFormThanks] = useState(false)
  const [formMessage, setFormMessage] = useState({ text: "", error: false })

  const lastStep = 4
  const [step, setStep] = useState(0)

  const validateStep = (step = 0, errors, values) => {
    switch (step) {
      case 0:
        return (
          ("name" in errors ||
            "surname" in errors ||
            values.name.length == 0 ||
            values.surname.length == 0) == false
        )

      case 1:
        return ("email" in errors || values.email.length == 0) == false

      case 2:
        return ("phone" in errors || values.phone.length == 0) == false

      case 3:
        return ("message" in errors || values.message.length == 0) == false

      case 4:
        return ("checkbox" in errors || values.checkbox == false) == false

      default:
        return false
    }
  }

  const previousStep = () => setStep(step > 0 ? step - 1 : 0)

  const nextStep = (errors, values) => {
    setContinueClicked(true)
    if (validateStep(step, errors, values)) {
      setStep(step < lastStep ? step + 1 : lastStep)
      setContinueClicked(false)
    }
  }

  return (
    <Wrapper id="contact">
      <ContentWrapper>
        <HeadingTwo>{heading}</HeadingTwo>
        <Text dangerouslySetInnerHTML={{ __html: content }} />
      </ContentWrapper>
      <FormWrapper>
        <Formik
          initialValues={{
            name: "",
            surname: "",
            email: "",
            phone: "",
            message: "",
            checkbox: false,
          }}
          validationSchema={contactSchema}
          onSubmit={(
            { name, surname, email, phone, message, checkbox },
            { setSubmitting, resetForm }
          ) => {
            ;(async () => {
              try {
                setSubmitting(true)

                const params = new FormData()
                params.set("name", name)
                params.set("surname", surname)
                params.set("email", email)
                params.set("phone", phone)
                params.set("message", message)
                params.set("consent1", String(checkbox))

                const response = await axios.post(
                  "https://cms.kolarski-re.com/mailer/mailer-main.php",
                  params
                )

                setSubmitting(false)
                if (response.data.code !== 200) {
                  throw new Error(response.data.message)
                }

                setFormMessage({
                  text: response.data.message,
                  error: false,
                })

                setFormMessage({
                  text: "",
                  error: false,
                })

                setFormThanks(true)

                setTimeout(() => {
                  setFormMessage({ text: "", error: false })
                  resetForm()
                  setSubmitClicked(false)
                  setStep(0)
                  setFormThanks(false)
                }, 5000)
              } catch (err) {
                setSubmitting(false)
                setFormMessage({
                  text: "Error",
                  error: true,
                })

                setTimeout(() => {
                  setFormMessage({ text: "", error: false })
                }, 2500)
              }
            })()
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form
              onSubmit={formValues => {
                setSubmitClicked(true)
                handleSubmit(formValues)
              }}
            >
              <FormBox step={`${step * 20}%`}>
                <FormStep visible={step === 0 ? true : false}>
                  <FormHeader>
                    <HeadingThree>
                      {lang == "en" ? "What is your name?" : "Wie heißen Sie?"}
                    </HeadingThree>
                  </FormHeader>
                  <FormRow>
                    <FormBlock>
                      <Input
                        required
                        label={lang == "en" ? "First Name" : "Vorname"}
                        name="name"
                        type="text"
                        value={values.name}
                        error={
                          continueClicked &&
                          (errors.name || values.name.length == 0)
                        }
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.name}
                        textLeft
                      />
                    </FormBlock>
                  </FormRow>
                  <FormRow>
                    <FormBlock>
                      <Input
                        required
                        label={lang == "en" ? "Last name" : "Nachname"}
                        name="surname"
                        type="text"
                        value={values.surname}
                        error={
                          continueClicked &&
                          (errors.surname || values.surname.length == 0)
                        }
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.surname}
                        textLeft
                      />
                    </FormBlock>
                  </FormRow>
                </FormStep>
                <FormStep visible={step === 1 ? true : false}>
                  <FormHeader>
                    <HeadingThree>
                      {lang == "en"
                        ? "Your E-Mail address"
                        : "Ihre E-Mail Adresse?"}
                    </HeadingThree>
                  </FormHeader>
                  <FormRow>
                    <FormBlock>
                      <Input
                        required
                        label={
                          lang == "en"
                            ? "E-Mail Address"
                            : "Ihre E-Mail Adresse"
                        }
                        name="email"
                        type="email"
                        value={values.email}
                        error={
                          continueClicked &&
                          (errors.email || values.email.length == 0)
                        }
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.email}
                        textLeft
                      />
                    </FormBlock>
                  </FormRow>
                </FormStep>
                <FormStep visible={step === 2 ? true : false}>
                  <FormHeader>
                    <HeadingThree>
                      {lang == "en"
                        ? "Your phone number"
                        : "Ihre Telefonnummer?"}
                    </HeadingThree>
                  </FormHeader>
                  <FormRow>
                    <FormBlock>
                      <Input
                        required
                        label={
                          lang == "en" ? "Telephone number" : "Telefonnummer"
                        }
                        name="phone"
                        type="tel"
                        value={values.phone}
                        error={
                          continueClicked &&
                          (errors.phone || values.phone.length == 0)
                        }
                        touched={touched.phone}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        textLeft
                      />
                    </FormBlock>
                  </FormRow>
                </FormStep>
                <FormStep visible={step === 3 ? true : false}>
                  <FormHeader>
                    <HeadingThree>
                      {lang == "en"
                        ? "Your message for us"
                        : "Ihre Nachricht an uns?"}
                    </HeadingThree>
                  </FormHeader>
                  <FormRow>
                    <FormBlock>
                      <Textarea
                        required
                        label={
                          lang == "en"
                            ? "Your message"
                            : "Bitte kontaktieren Sie mich."
                        }
                        name="message"
                        value={values.message}
                        error={
                          continueClicked &&
                          (errors.message || values.message.length == 0)
                        }
                        touched={touched.message}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </FormBlock>
                  </FormRow>
                </FormStep>
                <FormStep visible={step === 4 ? true : false}>
                  <FormRow margin="0">
                    <Checkboxes>
                      <Checkbox
                        name="checkbox"
                        value={values.checkbox}
                        error={submitClicked && errors.checkbox}
                        touched={touched.checkbox}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        contentBlack
                        setPageVisible={setPageVisible}
                        lang={lang}
                        content={
                          lang == "en"
                            ? "By submitting this form, you agree that your information from the contact form will be collected and processed to answer your request. The data will be deleted after the processing of your request. You can revoke your consent at any time for the future by sending an e-mail to info@kolarski-re.com. Detailed information on handling user data can be found in our privacy policy."
                            : "Mit dem Absenden dieses Formulars erklären Sie sich damit einverstanden, dass Ihre Angaben aus dem Kontaktformular zur Beantwortung Ihrer Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an info@kolarski-re.com widerrufen. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer Datenschutzerklärung."
                        }
                      />
                    </Checkboxes>
                  </FormRow>
                </FormStep>
              </FormBox>
              <FormControls>
                <ButtonSub type="button" onClick={previousStep}>
                  {lang == "en" ? "Back" : "ZURÜCK"}
                </ButtonSub>
                {step === lastStep ? (
                  <ButtonMain submit full disabled={isSubmitting} type="submit">
                    {!isSubmitting && lang == "en" ? "Continue" : "Weiter"}
                  </ButtonMain>
                ) : (
                  <ButtonMain
                    type={step === 4 ? "submit" : "button"}
                    onClick={e => {
                      e.preventDefault()
                      nextStep(errors, values)
                    }}
                  >
                    {lang == "en" ? "Continue" : "Weiter"}
                  </ButtonMain>
                )}
              </FormControls>
              {formMessage.text && (
                <Text error={formMessage.error}>{formMessage.text}</Text>
              )}
            </Form>
          )}
        </Formik>
        <FormThx active={formThanks}>
          <HeadingThree color="white">
            {lang == "en"
              ? "Thank you, we will get back to you shortly"
              : "Danke, wir werden uns in Kürze bei Ihnen melden"}
          </HeadingThree>
        </FormThx>
      </FormWrapper>
    </Wrapper>
  )
}

export default Contact
