import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`

const Field = styled.textarea`
  display: flex;
  align-items: center;
  background: #fff;
  color: ${({ error }) => (error ? "#ed454d" : "#000")};
  border: ${({ error }) => (error ? "1px solid #ed454d" : "none !important")};
  text-align: left;
  font-size: 16px;
  height: 135px;
  width: 100%;
  padding: 20px;
  width: 100%;
  resize: vertical;
  transition: all 0.3s, cubic-bezier(0.7, 0, 0.4, 1) ease-out;
`

const Label = styled.label`
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  display: none;
`

const Textarea = ({
  label,
  name,
  value,
  error,
  touched,
  handleChange,
  handleBlur,
  textLeft,
}) => (
  <Wrapper>
    <Label>{label}</Label>
    <Field
      active={value}
      value={value}
      error={error}
      onChange={handleChange}
      onBlur={handleBlur}
      name={name}
      touched={touched}
      textLeft={textLeft}
      placeholder={label}
    />
  </Wrapper>
)

export default Textarea
