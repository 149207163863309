import React from "react"
import styled, { css } from "styled-components"
import SwiperCore, { Navigation, A11y, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"

import LazyImage from "components/shared/LazyImage"
import { Text } from "components/shared/Typography"

import arrow from "assets/images/arrow.svg"

const Wrapper = styled.section`
  position: relative;
  height: calc(100vh - 40px);

  & .swiper-container {
    overflow: visible;
  }

  & .swiper-pagination {
    bottom: -50px;
  }

  & .swiper-pagination-bullet {
    height: 20px;
    width: 20px;
  }

  & .swiper-pagination-bullet-active {
    background-color: #02a9e7;
  }

  @media screen and (max-width: 1024px) {
    height: auto;
    margin-bottom: 60px;
  }
`

const Slide = styled.article`
  position: relative;
`

const ImageWrapper = styled.div`
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`
const Image = css`
  height: calc(100vh - 110px);
  width: 100%;
  object-fit: cover;
  object-position: top;

  @media screen and (max-width: 1024px) {
    height: auto;
    order: 2;
  }
`
const Label = styled.div`
  background: #fff;
  width: 25vw;
  box-sizing: border-box;
  color: #000;
  position: absolute;
  top: 0;
  left: 6vw;

  @media screen and (max-width: 1024px) {
    position: relative;
    order: 1;
    width: 100%;
    left: 0;
  }
`

const LabelHeader = styled.div`
  padding: 40px 40px 35px 40px;
  background-color: #000;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: 1024px) {
    height: auto;
    padding: 30px 10%;
  }
`

const LabelContent = styled.div`
  padding: 40px;

  @media screen and (max-width: 1024px) {
    padding: 30px 10%;
  }
`
const LabelGroup = styled.dl`
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const LabelTitle = styled(Text).attrs({
  as: "dt",
})`
  margin: 12px 0 0 0;

  &:first-of-type {
    margin: 0;
  }

  @media screen and (max-width: 1024px) {
    width: 50%;
    margin: 0 0 5px 0;
  }
`

const LabelValue = styled(Text).attrs({
  as: "dd",
})`
  margin: -8px 0 0 0;
  @media screen and (max-width: 1024px) {
    width: 50%;
    margin: 0 0 5px 0;
  }
`

const Controls = styled.nav`
  position: absolute;
  bottom: 0;
  left: 6vw;
  display: flex;
  width: 25vw;
  height: 70px;
  background-color: #000;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const ControlsButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  flex: 1;
  width: calc(100% / 3);
  background-color: #000;
  cursor: pointer;
  transition: all 0.3s, cubic-bezier(0.7, 0, 0.4, 1) ease-out;
  &:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background: url(${arrow}) no-repeat center / cover;
  }
  &:hover {
    background-color: #02a9e7;
  }
  &:disabled {
    cursor: auto;
    background-color: #000 !important;
    &:before {
      opacity: 0.5;
    }
  }
`

const ControlsPages = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: calc(100% / 3);
`
const ControlsPrev = styled.button`
  ${ControlsButton}
  transform: rotate(-180deg);
`
const ControlsNext = styled.button`
  ${ControlsButton}
`

SwiperCore.use([Navigation, A11y, Pagination])

const ProjectsSlider = ({ projects, setNavActive, lang }) => {
  const swiperSettings = {
    slidesPerView: 1,
    centeredSlides: true,
    loop: false,
    grabCursor: true,
    pagination: { clickable: true },
    breakpoints: {
      1024: {
        pagination: false,
      },
    },
    navigation: {
      prevEl: "#navigationPrev",
      nextEl: "#navigationNext",
    },
    onInit: swiper => {
      document.querySelector("#navigationPages").innerHTML = `1  ${
        lang == "en" ? "of" : "von"
      }  ${swiper.slides.length}`
    },
    onSlideChange: swiper => {
      document.querySelector("#navigationPages").innerHTML = `${
        swiper.activeIndex + 1
      } ${lang == "en" ? "of" : "von"} ${swiper.slides.length}`
    },
  }

  return (
    <Wrapper id="references">
      <Swiper {...swiperSettings}>
        {projects.map(item => (
          <SwiperSlide>
            <Slide>
              <ImageWrapper>
                <LazyImage css={Image} src={item.imageLazy} cover />
                <Label>
                  <LabelHeader>
                    <Text
                      color="white"
                      dangerouslySetInnerHTML={{ __html: item.address }}
                    />
                  </LabelHeader>
                  <LabelContent>
                    <LabelGroup>
                      <LabelTitle>{lang == "en" ? "Type" : "Typ"}</LabelTitle>
                      <LabelValue>{item.type}</LabelValue>
                      <LabelTitle>
                        {lang == "en" ? "Residentials" : "Wohneinheiten"}
                      </LabelTitle>
                      <LabelValue>{item.flats}</LabelValue>
                      <LabelTitle>
                        {lang == "en" ? "Commertials" : "Geschäftseinheiten"}
                      </LabelTitle>
                      <LabelValue>{item.bussinesUnits || "0"}</LabelValue>
                      <LabelTitle>{lang == "en" ? "Year" : "Jahr"}</LabelTitle>
                      <LabelValue>{item.year}</LabelValue>
                    </LabelGroup>
                  </LabelContent>
                </Label>
              </ImageWrapper>
            </Slide>
          </SwiperSlide>
        ))}
      </Swiper>
      <Controls>
        <ControlsPrev id="navigationPrev" />
        <ControlsPages id="navigationPages" color="white">
          1 {lang == "en" ? "of" : "von"} -
        </ControlsPages>
        <ControlsNext id="navigationNext" />
      </Controls>
    </Wrapper>
  )
}

export default ProjectsSlider
