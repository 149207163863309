import React from "react"
import styled from "styled-components"

import scrollToSection from "utils/scrollToSection"
import { HeadingThree } from "components/shared/Typography"
import ProjectCard from "components/projects/ProjectCard"

import patternGrey from "assets/images/pattern-grey.png"
import iconHand from "assets/icons/hand.png"

const Wrapper = styled.section`
  padding: 70px 0 0 0;
  display: flex;
  background: url(${patternGrey}) repeat-y top right;
  background-size: 33vw auto;

  @media screen and (max-width: 1024px) {
    background: none;
    padding: 20px 10% 100px 10%;
  }
`

const WrapperInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  & > * {
    width: 40vw;
    margin-top: 30px;
    margin-bottom: 100px;
    &:nth-child(2n + 2) {
      margin-top: 100px;
      margin-left: 6vw;
    }
  }

  @media screen and (max-width: 1024px) {
    & > * {
      width: 100%;
      &:nth-child(2n + 2) {
        margin-top: 0;
        margin-left: 0;
      }
    }
  }
`

const IconWrapper = styled.div`
  position: absolute;
  left: 40px;
  top: 40px;
`
const Icon = styled.img`
  height: 60px;
  transition: height 300ms ease-in-out, transform 300ms ease-in-out;
`

const Cta = styled.section`
  position: relative;
  margin-top: 15px !important;
`

const CtaInner = styled.div`
  position: relative;
  border: 5px solid #000;
  padding: 140px 40px 40px 40px;
  &:hover {
    & ${Icon} {
      transform: scale(1.15);
    }
  }

  & button {
    font-family: "futura-book", sans-serif;
  }

  @media screen and (max-width: 500px) {
    & * {
      font-size: 24px;
    }
    & button {
      white-space: nowrap;
      font-size: 23px;
      text-align: left;
      position: relative;
      left: -7px;
      margin-right: auto;
      display: inline-block;
    }

    padding: 140px 30px 30px 30px;
  }
`

const ProjectsGrid = props => {
  return (
    <Wrapper id="projects">
      <WrapperInner>
        {props.projects.map(project => {
          return (
            <ProjectCard
              imageLazy={project.imageLazy}
              image={project.image}
              address={project.address}
              flats={project.flats}
              bussinesUnits={project.bussinesUnits}
              type={project.type}
              website={project.website}
              year={project.year}
              lang={props.lang}
            />
          )
        })}
        {props.cta.status === true && (
          <Cta>
            <CtaInner>
              <IconWrapper>
                <Icon src={iconHand} />
              </IconWrapper>
              <HeadingThree>
                {props.cta.text}
                <button onClick={() => scrollToSection(`#contact`)}>
                  {props.cta.buttonText}
                </button>
              </HeadingThree>
            </CtaInner>
          </Cta>
        )}
      </WrapperInner>
    </Wrapper>
  )
}

export default ProjectsGrid
