import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { Text } from "components/shared/Typography"

import logoOnly from "assets/images/logo-only.png"

const Header = styled.div`
  padding-bottom: 50px;
`
const Brand = styled.img`
  width: 15%;
  @media screen and (max-width: 500px) {
    width: 25%;
  }
`

const navigationAnchor = css`
  position: relative;
  margin: 0 40px 0 0;
  padding: 0;
  font-size: 16px;
  line-height: 1;
  background-color: transparent;
  color: #000;
  &:before {
    content: "";
    width: 104%;
    height: 8px;
    background: #02a9e7;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.3;
    pointer-events: none;
  }
  &:last-of-type {
    margin: 0;
  }
`

const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 100px 0 0 0;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const NavigationLeft = styled.div`
  @media screen and (max-width: 500px) {
    margin: 0 0 40px 0;
  }
`
const NavigationRight = styled.div``
const NavigationButton = styled.button`
  ${navigationAnchor}
  cursor: pointer;
`
const NavigationLink = styled(Link)`
  ${navigationAnchor}
`

const NavigationLinkExt = styled(NavigationLink).attrs({
  as: "a",
})`
  margin: 0 40px 10px 0;
  display: inline-block;
`

const Content = styled.div`
  display: flex;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  width: 25vw;
  padding-right: 6vw;

  @media screen and (max-width: 1024px) {
    width: 50%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const Wrapper = styled.footer`
  width: 100%;
  padding: 100px 6vw 80px 6vw;
  border-bottom: 24px solid #000;

  @media screen and (max-width: 500px) {
    padding: 50px 10%;

    & ${NavigationRight} {
      & ${NavigationLinkExt} {
        font-size: 14px;
        margin: 0 20px 10px 0;
      }
    }
    & ${NavigationButton} {
      font-size: 14px;
    }
  }
`

const Footer = ({ setPageVisible, lang }) => {
  return (
    <Wrapper>
      <Header>
        <Brand src={logoOnly} />
      </Header>
      <Content>
        <Col>
          <Text>
            Kolarski real estate &amp; trading GmbH
            <br />
            Gipsstraße 5<br />
            10119 Berlin
          </Text>
          <Text>
            <NavigationLinkExt
              href="https://www.google.com.tr/maps/dir/''/kolarski+gmbh/@52.5270148,13.3296592,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x47a851fafb402923:0x3db4c44249141ff!2m2!1d13.3996987!2d52.5270355"
              target="_blank"
            >
              {lang == "en" ? "Directions" : "Anfahrt"}
            </NavigationLinkExt>
          </Text>
        </Col>
        <Col>
          <Text>
            T <a href="tel:+49(0)30 44013900">+49(0)30 44013900</a>
          </Text>
          <Text>
            F <a href="tel:+49(0)30 44013900">+49(0)30 44013900</a>
          </Text>
          <Text>
            E <a href="mailto:info@kolarski-re.com">info@kolarski-re.com</a>
          </Text>
        </Col>
      </Content>
      <Navigation>
        <NavigationLeft>
          <NavigationButton onClick={() => setPageVisible(1)}>
            {lang == "en" ? "Imprint" : "Impressum"}
          </NavigationButton>
          <NavigationButton onClick={() => setPageVisible(2)}>
            {lang == "en" ? "Privacy" : "Datenschutz"}
          </NavigationButton>
        </NavigationLeft>
        <NavigationRight>
          <NavigationLinkExt
            href="https://www.facebook.com/KolarskiRealEstate"
            target="_blank"
          >
            Facebook
          </NavigationLinkExt>
          <NavigationLinkExt
            href="https://www.instagram.com/kolarski_real_estate/"
            target="_blank"
          >
            Instagram
          </NavigationLinkExt>
          <NavigationLinkExt
            href="https://de.linkedin.com/company/kolarski-real-estate-&-trading-gmbh"
            target="_blank"
          >
            LinkedIn
          </NavigationLinkExt>
          <NavigationLinkExt
            href="https://www.xing.com/companies/kolarskirealestate&tradinggmbh"
            target="_blank"
          >
            Xing
          </NavigationLinkExt>
        </NavigationRight>
      </Navigation>
    </Wrapper>
  )
}

export default Footer
