import styled, { css } from "styled-components"

const Button = css`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 13px;
  width: 100%;
`

export const ButtonMain = styled.button`
  ${Button};

  background-color: #000;
  color: #fff;
`

export const ButtonSub = styled.button`
  ${Button};

  background-color: #fff;
  color: #000;
`

export const ButtonActive = styled.button`
  ${Button};

  background-color: #02a9e7;
  color: #fff;
`

export const ButtonAdd = styled.button`
  ${Button};

  background-color: #77d0f1;
  color: #fff;
`
